<template>
    <div class="container-fluid">
        <div class="row mt-2 mb-2" v-if="attachments.length > 0">
            <div class="col-md-3 col-sm-6 col-12 " v-for="(file, index) in attachments">
                <div class="row files">
                    <div class="col-2">
                        <i class="fas fa-file-upload fa-2x"></i>
                    </div>
                    <div class="col-10 position-relative">
                        <img
                            v-if="deletePermission"
                            @click.prevent="removeFile(index)"
                            class="position-absolute cursor-pointer top-0 right-0"
                            width="15"
                            src="/app-assets/images/icons/trash.svg"
                            alt=""
                        >
                        <a :href="file.file_path" target="_blank">
                          <h5 class="mb-0">
                            <span class="text-primary">{{ file.name }}</span>
                          </h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlobFileViewer",
    props: {
        deletePermission: {
          type: Boolean,
          default: true
        },
        attachments: {
            type: Array,
            default: () => []
        }
    },
    setup(props, {emit}) {
        const removeFile = (index) => {
            props.attachments.splice(index, 1);
            emit("update:modelValue", props.attachments)
        }

        return {
            removeFile
        }
    }
}
</script>

<style scoped>
    .files{
        border: 1px solid #2D2D2D;
        background-color: #f2f2f2;
        padding: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .mr-2{
        margin-right: 1.5rem;
    }
    .right-0{
        right: 0;
    }
</style>