<template>
  <div class="row journal-line border-rad-8">
    <div class="col-12 d-flex product-details-border position-relative pe-0">
      <div class="row w-100 pe-lg-0 me-1 py-2">

        <div v-if="isItem" class="col-lg-4 col-12 mt-1">
          <div class="mb-1">
            <label class="form-label" for="product_id">Product</label>
            <vField
                v-model="item.product_id"
                name="product_id"
                type="text"
                class="form-control d-none"
            />
            <v-select
                placeholder="Select Product / Service"
                v-model="item.product_id"
                :options="products"
                label="text"
                :reduce="text => text.id"
                :disabled="isAllocate || isDisabled"
            />
          </div>
        </div>
        <div v-else class="col-md-4 col-12 mt-1">
          <div class="mb-1">
            <label class="form-label" for="account_head_id">Account Head</label>
            <vField
                v-model="item.account_head_id"
                name="account_head_id"
                id="account_head_id"
                type="text"
                class="form-control d-none"
            />
            <v-select
                placeholder="Select Head"
                v-model="item.account_head_id"
                :options="accountHeads"
                label="name"
                :reduce="name => name.id"
                :disabled="isAllocate || isDisabled"
            />
          </div>
        </div>

        <template v-if="isItem">
          <div class="col-lg-2 col-6 mt-1">
            <div class="mb-1">
              <label class="form-label" for="rate">Unit</label>
              <input
                  :value="unit"
                  type="text"
                  readonly
                  class="form-control text-start"
                  placeholder="Unit"
              >
            </div>
          </div>
          <div class="col-lg-2 col-6 mt-1">
            <div class="mb-1">
              <label class="form-label" for="rate">Rate</label>
              <input
                  v-model="item.rate"
                  type="number"
                  :readonly="isAllocate || isDisabled"
                  class="form-control text-end"
                  placeholder="Rate"
              >
            </div>
          </div>
          <div class="col-lg-2 col-6 mt-1">
            <div class="mb-1">
              <label class="form-label" for="qty">
                QTY <span v-if="productStock !== null">({{productStock}})</span>
                <strong style="color: red">*</strong>
              </label>
              <input
                  v-model="item.quantity"
                  type="number"
                  class="form-control text-end"
                  placeholder="Qty"
                  :readonly="isAllocate || isDisabled"
              >
            </div>
          </div>
        </template>

        <div v-if="isItem" class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="amount">Amount</label>
            <input
                :value="amount"
                type="number"
                readonly
                class="form-control text-end"
                placeholder="Amount"
            >
          </div>
        </div>
        <div v-else class="col-md-2 col-12 mt-1">
          <div class="mb-1">
            <div class="mb-1">
              <label class="form-label" for="amount">Amount</label>
              <vField
                  v-model="item.amount"
                  name="amount"
                  type="number"
                  :readonly="isAllocate || isDisabled"
                  class="form-control text-right"
                  placeholder="amount"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="vat_type">VAT</label>
            <vField
                v-model="item.vat"
                id="vat"
                as="select"
                name="vat"
                class="form-select text-right"
                :disabled="isAllocate || isDisabled"
            >
                <option 
                    v-for="(vat, i) in vatRate"
                    :value="vat.value"
                    :key="i"
                >
                    {{vat.label}}
                </option>
            </vField>
          </div>
        </div>

        <div class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="vat_amount">VAT Amount</label>
            <input
                :value="vatAmount"
                type="number"
                readonly
                class="form-control text-end"
                placeholder="Vat Amount"
            >
          </div>
        </div>

        <div class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label">Expected Delivery Date</label>
            <input type="date" class="form-control" v-model="item.expected_delivery_date" :disabled="isDisabled || (item.status && item.status != 'pending')">
          </div>
        </div>
 
        <div class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="vat_amount">Total BOQ Qty</label>
            <input
                v-model="item.total_boq_qty"
                type="number"
                readonly
                class="form-control text-end"
                placeholder="Total BOQ Qty"
            >
          </div>
        </div>

        <div class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="vat_amount">Remaining BOQ Qty</label>
            <input
                :value="item.remaining_boq_qty"
                type="number"
                readonly
                class="form-control text-end"
                placeholder="Remaining BOQ Qty"
            >
          </div>
        </div>

        <div class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label">
              Stock in hand 
            </label>
            <input
                v-model="item.stock_note"
                type="number"
                class="form-control text-end"
                placeholder="Stock in hand"
                readonly
            >
          </div>
        </div>

        <div class="col-lg-5 col-12 mt-1">
          <div class="mb-1">
            <label class="form-label" for="note">Note</label>
            <textarea
                v-model="item.description"
                placeholder="Description"
                class="form-control"
                rows="1"
                :readonly="isAllocate || isDisabled"
            />
          </div>
        </div>

        <div v-if="isAllocate" class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="vat_type">Procurement Type <strong style="color: red">*</strong></label>
            <vField
                v-model="item.procurement_type"
                name="procurement_type"
                type="text"
                class="form-control d-none"
            />
            <v-select
                placeholder="Select"
                v-model="item.procurement_type"
                :options="procurementTypes"
                label="text"
                :reduce="text => text.id"
                :disabled="item.status != 'pending'"
            />
          </div>
        </div>
        <div v-if="isAllocate" class="col-lg-2 col-6 mt-1">
          <div class="mb-1">
            <label class="form-label" for="vat_type">Purchase Location <strong style="color: red">*</strong></label>
            <vField
                v-model="item.purchase_location"
                name="purchase_location"
                type="text"
                class="form-control d-none"
            />
            <v-select
                placeholder="Select"
                v-model="item.purchase_location"
                :options="purchaseLocations"
                label="text"
                :reduce="text => text.id"
                :disabled="item.status != 'pending'"
            />

          </div>
        </div>
      </div>

      <div
          @click="$emit('onClose', index)"
          class="d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-x cursor-pointer font-medium-3">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import figureFormatter from '@/services/utils/figureFormatter'
import {inject, onUpdated, watch} from "vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const {commaFormat} = figureFormatter()
const showError = inject('showError');
const {fetchProductStock} = handlePurchaseAndSales();
const route = useRoute();
const productStock = ref(0);
const isPauseWatcher = ref(true);

const pr = defineProps({
  item: {
    type: Object
  },
  index: {
    type: Number
  },
  projectId: {
    type: Number,
  },
  warehouseId: {
    type: Number,
    default: 0
  },
  products: {
    type: Array
  },
  accountHeads: {
    type: Array
  },
  vatRate: {
    type: Array
  },
  isItem: {
    type: Boolean
  },
  isRequisitionCreate: {
    type: Boolean
  },
  isAllocate: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  procurementTypes: {
    type: Array,
    default: [],
  },
  purchaseLocations: {
    type: Array,
    default: [],
  },
  requisitionDate: {
    type: String,
  },
})

const amount = computed(() => {
  if(pr.isItem) {
    return pr.item.rate * pr.item.quantity
  }
  if(!pr.isItem) {
    return pr.item.amount
  }
})
const vatAmount = computed(() => {
  if(pr.item.vat && pr.isItem) {
    return (pr.item.vat/100 * amount.value).toFixed(2)
  } else {
    return (pr.item.vat/100 * pr.item.amount).toFixed(2)
  }
  return 0
})

watch(vatAmount, (newValue, oldValue) => {
  if(isNaN(oldValue)){
    pr.item.vat_amount  = pr.item.vat_amount;
  }else {
    pr.item.vat_amount  = newValue;
  }
})

const totalAmount = computed(() => {
  if(pr.item.vat == 0) {
    return amount.value
  }
  if(pr.isItem) {
    return parseInt(amount.value) + parseInt(vatAmount.value)
  }
  return parseInt(pr.item.amount) + parseInt(vatAmount.value)
})
const productId = computed(() => pr.item.product_id)
const expectedDeliveryDate = computed(() => pr.item.expected_delivery_date)
const today = new Date().toISOString().split('T')[0];
const productList = computed(() => pr.products)
let unit = ref('');

watch(expectedDeliveryDate, (newValue, oldValue) => {
  if(newValue !== oldValue) {
    let newDate = new Date(newValue);
    let requisitionDate = new Date(pr.requisitionDate);
    if (newDate < requisitionDate){
      showError("Expected delivery Date can't be set before the day of requisiton.");
      pr.item.expected_delivery_date = today;
    }
  }
})

watch(productId, (newValue, oldValue) => {
  if(newValue === null) {
    clearFields();
    return;
  }

  getUnit();
  fetchStock(newValue);
})

const fetchStock = (id) => {
  let query = `?company_id=${route.params.companyId}&skip_stock_check=1`;
  if (pr.projectId) {
    query += `&project_id=${pr.projectId}`
  }
  
  if(pr.warehouseId){
    query += `&warehouse_id=${pr.warehouseId}`
  }
  
  const pauseWatcher = isPauseWatcher && !pr.isRequisitionCreate;

  fetchProductStock(id, query).then(res => {
    
    productStock.value = 0;

    if(res.status === false) {
      pr.item.stock = productStock.value = 0;
      return;
    }

    if (res.data.hasOwnProperty('boq_quantity') && !pauseWatcher) {
      pr.item.total_boq_qty = res.data.boq_quantity;
    }

    if (res.data.hasOwnProperty('boq_quantity') && res.data.hasOwnProperty('grn_quantity') && !pauseWatcher) {
      pr.item.remaining_boq_qty = res.data.boq_quantity - res.data.grn_quantity;
    }

    isPauseWatcher.value = false;
    productStock.value = res.data.stock;
    pr.item.stock = productStock.value;

    pr.item.stock_note = 0;

    if(res.data.warehouse_stock){
      pr.item.stock_note = res.data.warehouse_stock;
    }else if(!res.data.warehouse_stock && res.data.stock){
      pr.item.stock_note = res.data.stock;
    }

  })
}

const getUnit = () => {
  (productList.value).forEach((item) => {
    if (item.id == productId.value){
      unit.value = item?.description?.unit?.name;
      return;
    }
  });
}

onUpdated(() => {
  if (! pr.isRequisitionCreate){
    getUnit();
  }
})

const clearFields = () => {
  productStock.value = null;
  pr.item.product_id = null;
  pr.item.quantity = 0;
  pr.item.rate = 0;
  pr.item.stock_note = 0;
}
</script>
<style scoped>
.journal-line {
  border: 1px solid #f0f2f5;
}

.journal-line:hover {
  background: #f0f2f5;
}
</style>
